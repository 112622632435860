import { IntegrationConfigurationTemplateAPI } from './api/integrationConfigurationTemplate/integrationConfigurationTemplateAPI';
import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { logOutOn401 } from './middleware/logOutOn401';
import companiesReducer from './slices/companiesSlice';
import companyUsersReducer from './slices/companyUsersSlice';
import userReducer from './slices/userAuthSlice';
import companyCrmUsersReducer from './slices/companyCrmUsersSlice';
import feedbackReducer from './slices/feedbackSlice';
import companyCrmObjectTypesReducer from './slices/companyCrmObjectTypesSlice';
import { CompanyDefinitionAPI } from './api/companyDefinition/CompanyDefinitionAPI';
import { rtkQueryErrorHandler } from './middleware/rtkQueryErrorHandler';
import { IntegrationConfigurationAPI } from './api/integrationConfiguration/IntegrationConfigurationAPI';

const rootReducer = combineReducers({
    companies: companiesReducer,
    companyCrmObjectTypes: companyCrmObjectTypesReducer,
    companyUsers: companyUsersReducer,
    userAuth: userReducer,
    companyCrmUsers: companyCrmUsersReducer,
    feedback: feedbackReducer,
    [CompanyDefinitionAPI.reducerPath]: CompanyDefinitionAPI.reducer,
    [IntegrationConfigurationAPI.reducerPath]: IntegrationConfigurationAPI.reducer,
    [IntegrationConfigurationTemplateAPI.reducerPath]: IntegrationConfigurationTemplateAPI.reducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['userAuth'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
    configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            })
                .prepend(logOutOn401.middleware)
                .concat(CompanyDefinitionAPI.middleware)
                .concat(IntegrationConfigurationAPI.middleware)
                .concat(IntegrationConfigurationTemplateAPI.middleware)
                .concat(rtkQueryErrorHandler),
        devTools: true,
        preloadedState,
    });

export const store = setupStore();
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
